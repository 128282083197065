import IssueTrendContainer from '../../issue-trend/containers/IssueTrendContainer';
import ServerlessMetadata from '../common/components/IssueDrawer/IssueDrawerTabs/ServerlessMetadata';
import AggregationsAdditionalTabsTableContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/AggregationsTableTab/AggregationsAdditionalTabsTableContainer';
import AggregationsTableTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/AggregationsTableTab/AggregationsTableTabContainer';
import AttackPathTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/AttackPathTab/AttackPathContainer';
import ChatGPTTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/ChatGPTTab/ChatGPTTabContainer';
import ComplianceTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/ComplianceTab/ComplianceTabContainer';
import DependencyGraphTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/DependencyGraphTab/DependencyGraphTabContainer';
import DevSummaryTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/DevSummaryTab/DevSummaryTabContainer';
import FixAppliedTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/FixAppliedTab/FixAppliedTabContainer';
import IssueApiInventoriesTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueApiInventoriesTab/IssueApiInventoriesTabContainer';
import IssueAppInfoTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueAppInfoTab/IssueAppInfoTabContainer';
import IssueCweListContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueCweListTab/IssueCweListContainer';
import IssueSummaryTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueSummaryTab/IssueSummaryTabContainer';
import VulnerabilitiesTableTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssuesCardVulnerabilitiesTab/containers/VulnerabilitiesTableTabContainer';
import JiraTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/JiraTab/JiraTabContainer';
import MoreInfoTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/MoreInfoTab/MoreInfoTabContainer';
import OscarIssueMatrixTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/OscarIssueMatrixTab/ComplianceTabContainer';
import PolicyTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/PolicyTab/PolicyTabContainer';
import PrAppliedTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/PrAppliedTab/PrAppliedTabContainer';
import SBOMChecksTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/SBOMChecksTab/SBOMChecksTabContainer';
import SBOMInfoTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/SBOMInfoTab/SBOMInfoTabContainer';
import SeverityFactorsTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/SeverityFactorsTab/SeverityFactorsTabContainer';
import { issueDrawerTabIcon } from '../common/utils/issue-utils';

export const issueDrawerTabsList = {
  summary: {
    component: IssueSummaryTabContainer,
    icon: issueDrawerTabIcon.summary,
  },
  serverlessMetadata: {
    component: ServerlessMetadata,
    icon: issueDrawerTabIcon.summary,
  },
  devSummary: {
    component: DevSummaryTabContainer,
    icon: issueDrawerTabIcon.devSummary,
  },
  attackPath: {
    component: AttackPathTabContainer,
    icon: issueDrawerTabIcon.attackPath,
  },
  appInfo: {
    component: IssueAppInfoTabContainer,
    icon: issueDrawerTabIcon.appInfo,
  },
  vulnerabilities: {
    component: VulnerabilitiesTableTabContainer,
    icon: issueDrawerTabIcon.vulnerabilities,
  },
  resolvedVulnerabilities: {
    component: VulnerabilitiesTableTabContainer,
    icon: issueDrawerTabIcon.resolvedVulnerabilities,
  },
  aggregation: {
    component: AggregationsTableTabContainer,
    icon: issueDrawerTabIcon.aggregation,
  },
  artifactFiles: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: issueDrawerTabIcon.artifactFiles,
  },
  cloudResources: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: issueDrawerTabIcon.cloudResources,
  },
  policySecurityScanSCA: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: issueDrawerTabIcon.policySecurityScanSCA,
  },
  policySecurityContainerScan: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: issueDrawerTabIcon.policySecurityContainerScan,
  },
  apis: {
    component: IssueApiInventoriesTabContainer,
    icon: issueDrawerTabIcon.apis,
  },
  compliance: {
    component: ComplianceTabContainer,
    icon: issueDrawerTabIcon.compliance,
  },
  dependencyGraph: {
    component: DependencyGraphTabContainer,
    icon: issueDrawerTabIcon.dependencyGraph,
  },
  sbomInfo: {
    component: SBOMInfoTabContainer,
    icon: issueDrawerTabIcon.sbomInfo,
  },
  sbomChecks: {
    component: SBOMChecksTabContainer,
    icon: issueDrawerTabIcon.sbomChecks,
  },
  oscar: {
    component: OscarIssueMatrixTabContainer,
    icon: issueDrawerTabIcon.oscar,
  },
  cwe: {
    component: IssueCweListContainer,
    icon: issueDrawerTabIcon.cwe,
  },
  severityFactors: {
    component: SeverityFactorsTabContainer,
    icon: issueDrawerTabIcon.severityFactors,
  },
  tickets: {
    component: JiraTabContainer,
    icon: issueDrawerTabIcon.tickets,
  },
  moreInfo: {
    component: MoreInfoTabContainer,
    icon: issueDrawerTabIcon.moreInfo,
  },
  policy: {
    component: PolicyTabContainer,
    icon: issueDrawerTabIcon.policy,
  },
  fixApplied: {
    component: FixAppliedTabContainer,
    icon: issueDrawerTabIcon.fixApplied,
  },
  prApplied: {
    component: PrAppliedTabContainer,
    icon: issueDrawerTabIcon.prApplied,
  },
  chatGpt: {
    component: ChatGPTTabContainer,
    icon: issueDrawerTabIcon.chatGpt,
  },
  trend: {
    component: IssueTrendContainer,
    icon: issueDrawerTabIcon.trend,
  },
};
