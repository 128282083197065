import type { ApolloError } from '@apollo/client';
import type { SxProps, TableCellProps, Theme } from '@mui/material';
import type { Nullable, OneOfValues } from './typescript-types';
import { Severities } from './severity-types';
import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';

export interface Column extends TableCellProps {
  header: string;
  key: string;
  sticky?: boolean;
  sortable?: boolean;
  left?: number;
  info?: string;
}

export enum ApolloClientCache {
  NoCache = 'no-cache',
  CacheFirst = 'cache-first',
  NetworkOnly = 'network-only',
}

export interface ServiceExecute<Input, Output> {
  execute: (param: Input) => Promise<Output>;
}

export const AppSeverity = {
  High: 'high',
  Medium: 'medium',
  Low: 'low',
} as const;

export const Trend = {
  Negative: 'negative',
  Neutral: 'neutral',
  Positive: 'positive',
} as const;

export interface ApolloCustomError extends Omit<ApolloError, 'networkError'> {
  networkError: {
    name: string;
    response: unknown;
    statusCode: number;
    result: {
      errorMessage: string;
    };
  };
  message: string;
}

export enum DateUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}
export interface IDateRange {
  limit: number;
  unit: DateUnit;
}

export enum Relevance {
  Irrelevant = 'irrelevant',
  Relevant = 'relevant',
  Default = 'default',
}

export enum SbomType {
  Repo = 'repo',
  Image = 'image',
}

export interface OrgSbom {
  scanId: string;
  scanDate: number;
  sbom: string;
  sbomCSV: string;
  sbomJSON: string;
  fileName: string;
}

export const AppOwnerRole = {
  Dev: 'Dev',
  Business: 'Business',
  Security: 'Security',
  Watcher: 'Watcher',
} as const;

export type AppOwnerRoleType = OneOfValues<typeof AppOwnerRole> | string;
export interface AppOwner {
  name: string;
  email: string;
  roles: AppOwnerRoleType[];
}

export type AppOwnerType = Pick<AppOwner, 'email' | 'name'>;
export interface OwnerForm {
  name: string;
  email: string;
  invalidEmailMsg: string;
  invalidNameMsg: string;
}

// for backend
export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

//for MUI
export enum MUIOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface AggregationValue {
  value: string;
  href?: Nullable<string>;
}

export interface IAggregationRow {
  value: AggregationValue[];
}

export interface DynamicJiraField {
  value: string;
  id: string;
}

export type DynamicFieldsInputType = DynamicJiraField | DynamicJiraField[];
export interface JiraTicket {
  dynamicFields: Nullable<{
    [key: string]: DynamicFieldsInputType;
  }>;
  fieldsMetadata?: Nullable<{
    [key: string]: IssueTypeField;
  }>;
  summary: Nullable<string>;
  issueType: Nullable<JiraIssueType>;
  project: Nullable<JiraProject>;
  assignee: Nullable<JiraUser>;
  fallbackAssignee: Nullable<JiraUser>;
  reporter: Nullable<JiraUser>;
  priority: Nullable<string>;
  epicLink: Nullable<JiraEpic>;
  issueId?: Nullable<string>;
  issueName?: string;
  appName?: string;
  appId?: string;
  category?: string;
  labels: Nullable<string[]>;
  components: Nullable<JiraComponent[]>;
  description?: Nullable<{
    category: string;
    policyName: string;
    appName: string;
    fixRecommendation?: string;
    secondTitle: string;
    fixLink?: string;
    oxIssueURL?: string;
  }>;

  aggregationsCount?: Nullable<number>;
  aggregations?: Nullable<IAggregationRow[]>;
  comment: Nullable<string>;
  aggItemsIds?: Nullable<string[]>;
}

export interface JiraTicketStatus {
  id?: Nullable<string>;
  key: string;
  self: string;
  fields: Nullable<JiraTicketFields>;
}

export interface JiraProject {
  key: string;
  id: string;
  name?: Nullable<string>;
}

export interface JiraTicketFields {
  created: string;
  project: JiraProject;
  reporter: Nullable<JiraUser>;
  assignee: Nullable<JiraUser>;
  updated: string;
  status: Status;
  summary: Nullable<string>;
}

export interface Status {
  name: string;
  statusCategory: StatusCategory;
}

export interface StatusCategory {
  name: string;
  key: string;
}

export interface JiraIssueType {
  name: string;
  id: string;
  fields: IssueTypeField[];
  error?: string;
}
export interface IssueTypeField {
  required: boolean;
  hasDefaultValue: boolean;
  name: string;
  key: string;
  allowedValues: Nullable<{ value: string; id: string }[]>;
  schema: Nullable<{
    type: OneOfValues<typeof FieldSchemaType>;
    system?: string;
  }>;
  operations?: string[];
  error?: string;
}

export enum JiraFieldName {
  epicLink = 'epic link',
  parent = 'parent',
}

export interface JiraUser {
  emailAddress: string;
  accountId: string;
  name: string;
  displayName?: string;
}

export interface JiraComponent {
  id: string;
  name: string;
}

export interface JiraEpic {
  id: string;
  key: string;
  fields: Fields;
}

export interface Fields {
  summary: string;
}

export interface JiraPriority {
  id: string;
  name: string;
}

export interface baseOption {
  id: string;
  name: string;
}
export enum TicketProvider {
  Jira = 'jira',
  Monday = 'monday',
  Slack = 'slack',
  AzureBoards = 'AzureBoards',
}

export enum scanTypes {
  FullScan = 'fullScan',
  PipelineScan = 'pipelineScan',
}
export interface TicketRef {
  provider: TicketProvider;
  key: string;
  ticketId?: Nullable<string>;
  link: string;
  fields?: Nullable<JiraTicketFields>;
  status?: Nullable<string>;
  created?: string;
  updated?: string;
  assignTo?: Nullable<baseOption>;
  project?: baseOption;
  reporter?: baseOption;
  ticketLink?: string;
}

export interface AggregationColumn {
  header: string;
  key: string;
  tooltip?: string;
  href: string;
  type: ColumnType;
  sx?: SxProps<Theme>;
}

export enum ColumnType {
  Date = 'date',
  User = 'user',
  Match = 'match',
  DaysOpen = 'daysOpen',
  Location = 'location',
  FileName = 'fileName',
  LinkToExternalProduct = 'linkToExternalProduct',
  PopUp = 'popup',
}
export interface AggregationItem {
  [key: string]: string;
}

export interface DateRange {
  from: number;
  to: number;
}

export const UNATTACHED_EVENTS_APP_TYPE = 'unattachedEvents';

export enum CategoryRiskNames {
  appox = 'Appoxalypse',
  critical = 'Critical',
  high = 'High',
  medium = 'Medium',
  low = 'Low',
  info = 'Info',
}

export enum SeverityType {
  Appoxalypse = 'appox',
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Info = 'info',
  None = 'none',
}

export const InventoryFilter = {
  New: 'New',
  InDevelopment: 'InDevelopment',
  DeployedProd: 'DeployedProd',
  ExternallyFacing: 'ExternallyFacing',
} as const;
// See why "as const" here: https://stackoverflow.com/a/63620855/17566189

export type InventoryFilterType = OneOfValues<typeof InventoryFilter>;
export interface FetchOrgScanInfoInput {
  dateRange?: DateRange;
  orderBy?: OrderBy;
  limit?: number;
  filters?: Array<InventoryFilterType>;
  owners?: string[];
  scanId?: string;
  appIds?: string[];
  tagIds?: string[];
}

export interface OrderBy {
  field: OrderByField;
  direction: Direction;
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum OrderByField {
  ScoreCreationTime = 'scanDate',
}

export interface OnboardingIDPCallbackDetail {
  connectorId: string;
  error?: string;
}

export interface SeverityAlert {
  appox?: number;
  critical: number;
  high: number;
  medium: number;
  low?: number;
  info?: number;
}

export const FieldSchemaType = {
  Array: 'array',
  Date: 'date',
  Number: 'number',
  Option: 'option',
  String: 'string',
} as const;

export interface Category {
  categoryName?: CategoryDisplayName;
  catId: number;
  severities: Severities;
  severityScore?: SeverityType;
  score: number;
  total?: number;
  isNa?: boolean;
  reason?: string[];
  name?: CategoryDisplayName;
}

export enum DetectionType {
  deps = 'Dependency',
  import = 'Import',
  token = 'Token / Secret',
  usage = 'SDK Usage',
  vanilla = 'Direct API Call',
}

export const BITBUCKET_SESSION_REDIRECT_TO_KEY = 'bitbucket_redirect_to';
export const BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING = 'onboarding';
