import { GlobalStyles } from '@mui/material';
import { HubSpotTrackingProvider } from 'hubspot';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useMountEffect } from 'react-utils';
import { TelemetryManager } from 'telemetry';
import { AppMenu } from '../../menu/components/AppMenu';
import ErrorBoundaryContainer from '../containers/ErrorBoundaryContainer';
import AppHeader from './AppHeader';
import { AppLayout } from './AppLayout';
import AppLoader from './AppLoader';
import MobileSheild from './MobileSheild';
import SnackbarProvider from './SnackbarProvider';
import ClearErrorOnRouteChange from './error-handling/ClearErrorOnRouteChange';
import AppCuesProvider from './providers/AppCuesProvider';
import AppNavigationProvider from './providers/AppNavigationProvider';
import ForceLogoutProvider from './providers/ForceLogoutProvider';
import RequiresAuthentication from './user/RequiresAuthentication';
import { FeatureFlagsHandler } from '../launch-darkly/FeatureFlagsHandler';
import { isDevelopment } from 'env-utils';
import { AppRoutesV1 } from './AppRoutesV1';
import { AppRoutesV2 } from './AppRoutesV2';

export const App: FC = () => {
  useMountEffect(() => {
    TelemetryManager.init();
  });

  return (
    <>
      <GlobalStyles
        styles={{
          'html,body, #root': { height: '100%', fontWeight: 300 },
        }}
      />
      <MobileSheild>
        <SnackbarProvider>
          <ErrorBoundaryContainer>
            <BrowserRouter>
              <AppNavigationProvider />
              <AppCuesProvider />
              <HubSpotTrackingProvider />
              <ForceLogoutProvider />
              <AppLoader>
                <AppLayout
                  header={<AppHeader />}
                  aside={
                    <RequiresAuthentication>
                      <AppMenu />
                    </RequiresAuthentication>
                  }>
                  <FeatureFlagsHandler />
                  {isDevelopment() ? <AppRoutesV2 /> : <AppRoutesV1 />}
                </AppLayout>
              </AppLoader>
              <ClearErrorOnRouteChange />
            </BrowserRouter>
          </ErrorBoundaryContainer>
        </SnackbarProvider>
      </MobileSheild>
    </>
  );
};
