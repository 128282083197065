import { Nullable } from 'ox-common-types';
import { InputOption } from '../store/create-ticket-store';

export enum TicketingVendor {
  Jira = 'Jira',
  AzureBoards = 'AzureBoards',
  Asana = 'Asana',
  Monday = 'Monday',
  GithubIssues = 'GithubIssues',
  ServiceNow = 'ServiceNow',
}

export const TicketingVendorDisplayName: Record<TicketingVendor, string> = {
  [TicketingVendor.Jira]: 'Jira',
  [TicketingVendor.AzureBoards]: 'Azure Boards',
  [TicketingVendor.Asana]: 'Asana',
  [TicketingVendor.Monday]: 'Monday',
  [TicketingVendor.GithubIssues]: 'Github Issues',
  [TicketingVendor.ServiceNow]: 'ServiceNow',
};

export enum TicketingAction {
  CreateTicket = 'CreateTicket',
  BulkCreateTicket = 'BulkCreateTicket',
  Link = 'Link',
  Unlink = 'Unlink',
}

export interface TicketFieldConfig {
  preFilledValue?: Nullable<InputOption>;
  preFilledValueObject?: Nullable<InputOption>;
  required: boolean;
  label: string;
  dropdownOptions?: Nullable<InputOption[]>;
  type: TicketFieldTypes;
  searchable: boolean;
  id: string;
  isFullWidth?: boolean;
  fetchOptionsOnce?: boolean;
  dependsOnFieldId?: string;
  dependsOnId?: string;
  bulkDescription?: string;
  allowFreeTextForDropdown?: boolean;
}

export enum TicketFieldTypes {
  Text = 'Text',
  Number = 'Number',
  Radio = 'Radio',
  MultiSelect = 'MultiSelect',
  Dropdown = 'Dropdown',
  Date = 'Date',
  DateTime = 'DateTime',
}

export type TicketOnInputChangeFunction = (
  fieldKey: string,
  value: string,
  isSearchable: boolean,
  dependsOnValue?: string,
  dependsOnId?: string,
) => void;

export type TicketOnInputSelectedFunction = (
  fieldKey: string,
  value: Nullable<string | InputOption | (string | InputOption)[]>,
) => void;
