import { createSimpleAsyncAction } from 'async-utils';
import { logger } from 'logging-utils';
import React from 'react';
import { closeSnackbar, openSnackbar } from 'snackbar-utils';
import { snapshot, useSnapshot } from 'valtio';
import { loadIrrelevantApps } from '../../irrelevant-apps/actions/irrelevant-apps-actions';
import {
  closeIssueModalWithoutNavigate,
  closeIssuesDrawer,
  getIssuesOpenFilterItems,
  resetSelected,
  setApplicationIrrelevantModal,
  setFalsePositiveToRawIssue,
  setPolicyDisableModal,
} from '../../issues/active-issues/store-actions/issue-store-actions';
import {
  setExcludeBulkIssueModal,
  setExcludeCategoryID,
  setExcludeIssueModal,
  setIsExcludeDefault,
  setShowReportFalsePositiveModal,
} from '../../issues/common/store-actions/issues-exclusions-store-actions';
import exclusionsService from '../services';
import { RemoveAlertExclusionInput } from '../services/remove-alert-exclusion/remove-alert-exclusion';
import { RemoveAppExclusionInput } from '../services/remove-app-exclusion/remove-app-exclusion';
import { RemovePolicyExclusionInput } from '../services/remove-policy-exclusion/remove-policy-exclusion';
import {
  EXCLUSIONS_CACHE_QUERY_NAMES,
  ExcludeAlertInput,
  ExcludeAlertsResponse,
  ExcludeAppInput,
  ExcludeInput,
  LoadExclusionsParams,
  ReportFPInput,
  UpdateExpiredAtByIdInput,
} from '../types/exclusion-types';

import { ApolloError } from '@apollo/client';
import { OxExclusionMode } from '@oxappsec/exclusion-service';
import { AppPages } from 'app-navigator';
import { SnackbarMessageWithLink } from 'ox-react-components';
import { isValidDate } from '../../api-key-settings/utils/date-utils';
import { loadInventory } from '../../dashboard-inventory/actions/inventory-actions';
import { dashboardStoreActions } from '../../dashboard/actions/dashboard-actions';
import {
  invalidateIssuesCachedQueries,
  loadIssues,
} from '../../issues/active-issues/actions/active-issues-actions';
import { getPipelineIssuesFilterItems } from '../../issues/pipeline-issues/actions/pipeline-filter-issues-actions';
import { loadIssuesPipeline } from '../../issues/pipeline-issues/actions/pipeline-issues-actions';
import { setExcludedByAlert } from '../../issues/pipeline-issues/store-actions/pipeline-issues-store-actions';
import {
  setBulkExcludeAlertLoading,
  setExclusions,
  setIsApplicationIrrelevantLoading,
  setIsDisablePolicyLoading,
  setIsExcludeAlertLoading,
  setIsLoadingRemoveSnooze,
  setIsRemoveExclusionLoading,
  setIsSnoozeBannerSnoozeOpen,
  setIsUpdateExpiredAtByIdLoading,
  setModalAction,
  setModalRemoveSnooze,
  setModalUpdateExpiredAt,
  setNearlyExpiredExclusions,
  setOffsetInStore,
  setTotalExclusionsFiltersNumber,
  setTotalExclusionsNumber,
  setTotalNearlyExpiredExclusions,
} from '../store-actions/exclusions-store-actions';
import ExclusionsStore from '../stores/exclusions-store';
import { snackBarSpplitingName } from '../utils/exclusion-utils';
import { loadExclusionsFilters } from './exclusions-filters-actions';
import { graphqlUtils } from 'api-clients/src/graphql-utils';
import { exclusionClient, reportClient } from 'api-clients';

export const loadExclusions = createSimpleAsyncAction(
  async (params?: LoadExclusionsParams) => {
    const { getMoreItems = false, cache = true, limit = 50 } = params || {};
    if (getMoreItems === false) {
      setOffsetInStore(0);
    }
    const { offset, totalExclusions } = snapshot(ExclusionsStore);

    if (offset >= totalExclusions && offset !== 0) {
      return;
    }

    const getExclusionsParams = {
      filters: ExclusionsStore.filterExclusionsBy,
      offset: offset,
      limit: limit,
    };

    const result = await exclusionsService.getExclusions.execute(
      getExclusionsParams,
      cache,
    );

    if (result) {
      setTotalExclusionsFiltersNumber(result.totalFilteredExclusions);
      setExclusions(result.exclusions, getMoreItems);
      setTotalExclusionsNumber(result.totalExclusions);
      setOffsetInStore(ExclusionsStore.exclusions.length);
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to load exclusions',
  },
);

export const getNearlyExpiredExclusions = async () => {
  const result = await exclusionsService.getNearlyExpiredExclusions.execute();
  if (result) {
    setTotalNearlyExpiredExclusions(result.count);
    setIsSnoozeBannerSnoozeOpen(result.count > 0);
    setNearlyExpiredExclusions(result.exclusions);
  }
};

export const irrelevantApp = createSimpleAsyncAction(
  async (input: ExcludeInput | ExcludeAppInput) => {
    try {
      setIsApplicationIrrelevantLoading(true);
      invalidateIssuesCachedQueries();
      graphqlUtils.invalidateCachedQueries(
        exclusionClient,
        EXCLUSIONS_CACHE_QUERY_NAMES,
      );
      const result = await exclusionsService.excludeApplications.execute([
        input,
      ]);
      if (result) {
        setTimeout(() => {
          setIsApplicationIrrelevantLoading(false);
          loadIssues({ cache: false, exclude: true });
          setApplicationIrrelevantModal(false);
          closeIssueModalWithoutNavigate();
          loadIrrelevantApps();
          getIssuesOpenFilterItems();
          loadInventory();
          dashboardStoreActions.loadDashboardInfo();
          openSnackbar(
            `The following app was made irrelevant: ${snackBarSpplitingName(
              input.appName,
            )}`,
            {
              variant: 'success',
            },
          );
        }, 5000);
      }
    } catch (err) {
      logger.error('Failed to load irrelevant app', err);
      setApplicationIrrelevantModal(false);
      openSnackbar(
        `There was a problem to make this app irrelevant: ${snackBarSpplitingName(
          input.appName,
        )}`,
        {
          variant: 'error',
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to exclude irrelevant app',
  },
);

export const disabledPolicy = createSimpleAsyncAction(
  async (input: ExcludeInput) => {
    try {
      setIsDisablePolicyLoading(true);
      invalidateIssuesCachedQueries();
      graphqlUtils.invalidateCachedQueries(
        exclusionClient,
        EXCLUSIONS_CACHE_QUERY_NAMES,
      );
      const result = await exclusionsService.excludePolicies.execute(input);
      if (result) {
        setTimeout(() => {
          resetSelected();
          setIsDisablePolicyLoading(false);
          setPolicyDisableModal(false);
          closeIssuesDrawer();
          loadIssues({ cache: false, exclude: true });
          getIssuesOpenFilterItems();
          loadInventory();
          openSnackbar(
            `The following policy has been disabled: ${snackBarSpplitingName(
              input.policyName,
            )}`,
            { variant: 'success' },
          );
        }, 5000);
      }
    } catch (err) {
      logger.error('Failed to load disable policy', err);
      setIsDisablePolicyLoading(false);
      setPolicyDisableModal(false);
      openSnackbar(
        `There was an error in disabling the policy: ${snackBarSpplitingName(
          input.policyName,
        )}`,
        {
          variant: 'error',
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to exclude disabled policy',
  },
);

export const excludeAlert = createSimpleAsyncAction(
  async (input: ExcludeAlertInput, issueName: string) => {
    try {
      if (input.expiredAt && !isValidDate(input.expiredAt)) {
        logger.error(`There was an error to change the expire date`);
        setIsUpdateExpiredAtByIdLoading(false);
        openSnackbar(`There was an error to change the expire date`, {
          variant: 'error',
        });
        return false;
      }
      setIsExcludeAlertLoading(true);
      invalidateIssuesCachedQueries();
      graphqlUtils.invalidateCachedQueries(
        exclusionClient,
        EXCLUSIONS_CACHE_QUERY_NAMES,
      );
      const result: ExcludeAlertsResponse =
        await exclusionsService.excludeAlert.execute(input);
      setExcludeCategoryID('');
      setIsExcludeDefault(false);
      if (result) {
        resetSelected();
        setIsExcludeAlertLoading(false);
        loadInventory();
        dashboardStoreActions.loadDashboardInfo();
        setExcludeIssueModal(false);
        if (input.exclusionMode === OxExclusionMode.pipelineScan) {
          graphqlUtils.invalidateCachedQueries(reportClient, ['getCICDIssues']);
          getPipelineIssuesFilterItems(null, true);
          loadIssuesPipeline({ cache: false, exclude: true, update: true });
          setExcludedByAlert(true);
        } else {
          getIssuesOpenFilterItems();
          loadIssues({ cache: false, exclude: true, update: true });
          closeIssuesDrawer();
        }
        getNearlyExpiredExclusions();
        openSnackbar(
          `The following issue has been excluded: ${snackBarSpplitingName(
            issueName,
          )}`,
          {
            variant: 'success',
          },
        );
      }
    } catch (err) {
      logger.error('Failed to load exclued issue', err);
      setIsExcludeAlertLoading(false);
      setExcludeIssueModal(false);
      openSnackbar(
        `There was an error excluding the issue: ${snackBarSpplitingName(
          issueName,
        )}`,
        {
          variant: 'error',
          autoHideDuration: 1000,
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to exclude alert',
  },
);

export const enablePolicy = createSimpleAsyncAction(
  async (input: RemovePolicyExclusionInput) => {
    try {
      setIsRemoveExclusionLoading(true);
      invalidateIssuesCachedQueries();
      graphqlUtils.invalidateCachedQueries(
        exclusionClient,
        EXCLUSIONS_CACHE_QUERY_NAMES,
      );
      const result = await exclusionsService.removePolicyExclusion.execute(
        input,
      );
      if (result) {
        setModalAction(false);
        loadExclusions({ cache: false });
        loadExclusionsFilters({ cache: false });
        setIsRemoveExclusionLoading(false);
        loadIssues({ cache: false, exclude: true });
        loadInventory();
        openSnackbar(
          `The following policy has been enabled: ${snackBarSpplitingName(
            input.policyName,
          )}`,
          {
            variant: 'success',
          },
        );
      }
    } catch (e) {
      setIsRemoveExclusionLoading(false);
      setModalAction(false);
      openSnackbar(
        `There was an error enabling this policy: ${snackBarSpplitingName(
          input.policyName,
        )}`,
        {
          variant: 'error',
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to remove policy exclusion',
  },
);

export const removeExclusion = createSimpleAsyncAction(
  async (input: RemoveAlertExclusionInput) => {
    try {
      setIsRemoveExclusionLoading(true);
      const result = await exclusionsService.removeAlertExclusion.execute(
        input,
      );
      if (result) {
        setModalAction(false);
        loadExclusions({ cache: false });
        loadExclusionsFilters({ cache: false });
        getNearlyExpiredExclusions();
        setIsRemoveExclusionLoading(false);
        loadInventory();
        openSnackbar(
          `The following issue exclusion has been removed: ${snackBarSpplitingName(
            input.issueName,
          )}`,
          {
            variant: 'success',
          },
        );
      }
    } catch (e) {
      logger.error(
        `There was an error removing exclusion: ${snackBarSpplitingName(
          input.issueName,
        )}`,
      );
      setIsRemoveExclusionLoading(false);
      setModalAction(false);
      openSnackbar(
        `There was an error removing exclusion: ${snackBarSpplitingName(
          input.issueName,
        )}`,
        {
          variant: 'error',
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to remove alert exclusion',
  },
);

export const removeApp = createSimpleAsyncAction(
  async (input: RemoveAppExclusionInput) => {
    try {
      setIsRemoveExclusionLoading(true);
      const result = await exclusionsService.removeAppExclusion.execute(input);
      if (result.isSucceded) {
        setIsRemoveExclusionLoading(false);
        setModalAction(false);
        loadExclusions({ cache: false });
        loadExclusionsFilters({ cache: false });
        loadInventory();
        openSnackbar(
          `The following application was made relevant: ${snackBarSpplitingName(
            input.appName,
          )}`,
          {
            variant: 'success',
          },
        );
      }
    } catch (e) {
      logger.error(
        `There was an error making this app relevant: ${snackBarSpplitingName(
          input.appName,
        )}`,
      );
      setIsRemoveExclusionLoading(false);
      openSnackbar(
        `There was an error making this app relevant: ${snackBarSpplitingName(
          input.appName,
        )}`,
        {
          variant: 'error',
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to remove app exclusion',
  },
);

export const updateExpiredAtById = createSimpleAsyncAction(
  async (input: UpdateExpiredAtByIdInput) => {
    try {
      if (input.expiredAt && !isValidDate(input.expiredAt)) {
        logger.error(`There was an error to change the expire date`);
        setIsUpdateExpiredAtByIdLoading(false);
        openSnackbar(`There was an error to change the expire date`, {
          variant: 'error',
        });
        return false;
      }
      setIsUpdateExpiredAtByIdLoading(true);
      const result = await exclusionsService.updateExpiredAtById.execute(input);
      if (result) {
        setIsUpdateExpiredAtByIdLoading(false);
        setModalUpdateExpiredAt(false);
        loadExclusions({ cache: false });
        loadExclusionsFilters({ cache: false });
        getNearlyExpiredExclusions();
        loadInventory();
        openSnackbar(
          `The expire date changed to: ${
            input.expiredAt ? input.expiredAt.toLocaleDateString() : 'Never'
          }`,
          {
            variant: 'success',
          },
        );
      }
    } catch (e) {
      logger.error(`There was an error to change the expire date`, e);
      setIsUpdateExpiredAtByIdLoading(false);
      openSnackbar(`There was an error to change the expire date`, {
        variant: 'error',
      });
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to remove app exclusion',
  },
);

export const removeSnoozeIssue = createSimpleAsyncAction(
  async (input: UpdateExpiredAtByIdInput) => {
    try {
      setIsLoadingRemoveSnooze(true);
      const result = await exclusionsService.updateExpiredAtById.execute(input);
      if (result) {
        setTimeout(() => {
          setIsLoadingRemoveSnooze(false);
          setModalRemoveSnooze(false);
          loadExclusions({ cache: false });
          loadExclusionsFilters({ cache: false });
          getNearlyExpiredExclusions();
          loadInventory();
          openSnackbar(`Snooze removed from issue`, {
            variant: 'success',
          });
        }, 5000);
      }
    } catch (e) {
      setIsLoadingRemoveSnooze(false);
      logger.error(`There was an error to remove snooze`, e);
      setIsUpdateExpiredAtByIdLoading(false);
      openSnackbar(`There was an error to remove snooze`, {
        variant: 'error',
      });
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to remove app exclusion',
  },
);

export const reportFalsePositiveAlert = createSimpleAsyncAction(
  async (input: ReportFPInput, issueName: string) => {
    const { isExclude } = input;

    try {
      setIsExcludeAlertLoading(true);
      invalidateIssuesCachedQueries();
      graphqlUtils.invalidateCachedQueries(
        exclusionClient,
        EXCLUSIONS_CACHE_QUERY_NAMES,
      );
      const result = await exclusionsService.reportFPAlert.execute(input);
      setFalsePositiveToRawIssue(input.reportedAlertInput.comment);
      if (!isExclude) {
        getIssuesOpenFilterItems();
        setIsExcludeAlertLoading(false);
        setShowReportFalsePositiveModal(false);
        openSnackbar(
          `The following issue was successfully reported as a False Positive: ${snackBarSpplitingName(
            issueName,
          )}`,
          {
            variant: 'success',
          },
        );
        return;
      }
      if (result) {
        setTimeout(() => {
          resetSelected();
          setShowReportFalsePositiveModal(false);
          setIsExcludeAlertLoading(false);
          closeIssuesDrawer();
          loadInventory();
          dashboardStoreActions.loadDashboardInfo();
          getIssuesOpenFilterItems();
          loadIssues({ cache: false, exclude: true });
          loadExclusions({ cache: false });
          loadExclusionsFilters({ cache: false });
          openSnackbar(
            `The following issue was successfully excluded and reported as a False Positive: ${snackBarSpplitingName(
              issueName,
            )}`,
            {
              variant: 'success',
            },
          );
        }, 5000);
      }
    } catch (err) {
      logger.error('Failed to load exclued issue', err);
      setIsExcludeAlertLoading(false);
      setExcludeIssueModal(false);
      openSnackbar(
        `There was an error excluding the issue: ${snackBarSpplitingName(
          issueName,
        )}`,
        {
          variant: 'error',
        },
      );
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to report as False Positive alert',
  },
);

export const excludeBulkAlerts = createSimpleAsyncAction(
  async (input: ExcludeAlertInput[]) => {
    const excludeBulkAlertsKey = 'excluding-bulk-issues';
    try {
      if (input[0].expiredAt && !isValidDate(input[0].expiredAt)) {
        logger.error(`There was an error to change the expire date`);
        setIsUpdateExpiredAtByIdLoading(false);
        openSnackbar(`There was an error to change the expire date`, {
          variant: 'error',
        });
        return false;
      }
      setBulkExcludeAlertLoading(true);
      setExcludeBulkIssueModal(false);
      openSnackbar(
        `Excluding ${input.length} selected issues in progress`,
        {
          variant: 'info',
          persist: true,
          key: excludeBulkAlertsKey,
        },
        { hideCloseIcon: true, isShowingSpinner: true },
      );
      invalidateIssuesCachedQueries();
      graphqlUtils.invalidateCachedQueries(
        exclusionClient,
        EXCLUSIONS_CACHE_QUERY_NAMES,
      );
      const result = await exclusionsService.excludeBulkAlert.execute(input);
      setExcludeCategoryID('');
      setIsExcludeDefault(false);
      if (result) {
        setTimeout(() => {
          resetSelected();
          setBulkExcludeAlertLoading(false);
          closeIssuesDrawer();
          loadInventory();
          dashboardStoreActions.loadDashboardInfo();
          getIssuesOpenFilterItems();
          loadIssues({ cache: false, exclude: true });
          getNearlyExpiredExclusions();
          const snackbarMessageWithLink = React.createElement(
            SnackbarMessageWithLink,
            {
              message: `${input.length} Issues have been excluded. You can view the excluded issues via the`,
              linkTitle: 'Exclusions page',
              linkHref: AppPages.Exclusions,
              shouldNavigate: true,
            },
          );
          closeSnackbar(excludeBulkAlertsKey);
          openSnackbar(snackbarMessageWithLink, {
            variant: 'success',
          });
        }, 5000);
      }
    } catch (err) {
      logger.error('Failed to load bulk exclueded issues', err);
      closeSnackbar(excludeBulkAlertsKey);
      setBulkExcludeAlertLoading(false);
      setExcludeBulkIssueModal(false);
      if (
        err instanceof ApolloError &&
        err.message.includes('Received status code 413')
      ) {
        openSnackbar(
          `Due too large amount of aggregations associated with selected issues action cannot be completed. Please reduce number of selected issues and try again.`,
          {
            variant: 'warning',
            persist: true,
          },
        );
      } else
        openSnackbar('There was an error excluding the issues', {
          variant: 'error',
        });
    } finally {
      getNearlyExpiredExclusions();
    }
  },
  {
    asyncState: ExclusionsStore.loading,
    errorMessage: 'Failed to exclude alert',
  },
);

export const clearStore = () => {
  ExclusionsStore.exclusions = [];
  ExclusionsStore.filterExclusionsBy = {};
  ExclusionsStore.totalExclusions = 0;
  ExclusionsStore.totalFilteredExclusions = 0;
};

export const useExclusions = () => useSnapshot(ExclusionsStore);
