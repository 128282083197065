import { getAuthToken } from 'common-auth';
import { Client, createClient } from 'graphql-ws';

export const createWsClient = (uri: string): Client => {
  const wsClient = createClient({
    url: uri.replace(/^http/, 'ws'),
    connectionParams: async () => {
      const token = await getAuthToken();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
    lazy: true,
  });

  return wsClient;
};
