import { isDevelopment } from 'env-utils';
import { privateRoutes } from './private-routes';
import { publicRoutes } from './public-routes';
import { privateNoOrgRoutes } from './private-no-org-routes';
import { RouteConfig } from './routing-types';

const filter = (routes: RouteConfig[]): RouteConfig[] => {
  if (isDevelopment()) {
    return routes;
  }

  // filter out routes behind a dev flag
  return routes.filter(r => !r.showOnlyInDev);
};

export const allRoutes = {
  private: filter(privateRoutes),
  public: filter(publicRoutes),
  privateNoOrg: filter(privateNoOrgRoutes),
};
