import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Theme,
  Toolbar,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import { AppPages, currentPage, navigateWithParameters } from 'app-navigator';
import { issuesCountNotExist } from 'common-utils';
import { FeedbackButton } from 'feedback-ui';
import { isNull } from 'lodash-es';
import {
  CategoryRiskNames,
  DateUnit,
  IDateRange,
  important,
} from 'ox-common-types';
import { ConditionalFiltersCondition } from 'ox-filter-utils';
import {
  IssuesBadgeHeader,
  ProtectingDevelopersBadge,
} from 'ox-react-components';
import { FC, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useMountEffect } from 'react-utils';
import { makeStyles } from 'tss-react/mui';
import { useSnapshot } from 'valtio';
import { ReactComponent as Logo } from '../../assets/icons/ox-built-in.svg';
import { ReactComponent as OxLogoTxt } from '../../assets/icons/ox-txt-logo.svg';
import DashboardStore from '../../dashboard/stores/dashboard-store';
import { AppMobileMenu } from '../../menu/components/AppMobileMenu';
import DemoScanButton from '../../new-scan/containers/DemoScanButton';
import ExceedScanLimitContainer from '../../new-scan/containers/ExceedScanLimitContainer';
import DeltaScanButton from '../../new-scan/containers/ScanButton';
import ScanStore from '../../new-scan/store/scan-store';
import DownloadsUsersDialog from '../../organizations/components/DownloadsUsersDialog';
import OrganizationSelectorContainerV1 from '../../organizations/containers/OrganizationSelectorContainerV1';
import OrganizationSelectorContainerV2 from '../../organizations/containers/OrganizationSelectorContainerV2';
import OrgInfoStore from '../../organizations/stores/org-info-store';
import { usePoliciesStore } from '../../policies/api/policies-api';
import LoginButtonContainer from '../containers/LoginButtonContainer';
import { setDateRange } from '../store-actions/top-bar-store-actions';
import DataViewSelectorContainer from './DataViewSelector/DataViewSelectorContainer';
import DateRangeSelector from './DateRangeSelector/DateRangeSelector';
import NonHistoricalDataTooltip from './NonHistoricalDataPopper/NonHistoricalDataTooltip';
import OpenDocsButton from './OpenDocsButton';
import ToggleThemeButton from './theme/ToggleThemeButton';
import UserMenuContainer from './user/user-menu/UserMenuContainer';
import { useOxFlags } from '../../feature-flags/api/feature-flags-api';
import TopBarStore from '../stores/top-bar-store';
import { isDevelopment } from 'env-utils';

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppHeader: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const theme = useTheme();

  const { orgScanInfo, orgScanInfoBreakdownInfo } = useSnapshot(DashboardStore);
  const { organization } = useSnapshot(OrgInfoStore);
  const { isAuthenticated, user } = useAuth0();
  const { dateRange } = useSnapshot(TopBarStore);
  const { enabledPolicies, totalPolicies } = usePoliciesStore();
  const { isDemoEnabled, realScanId } = useSnapshot(ScanStore);
  const disabledPolicies = totalPolicies - enabledPolicies;
  const currPage = currentPage();
  const isIssueCount = issuesCountNotExist(
    orgScanInfoBreakdownInfo?.violations,
  );
  const onDateRangeChange = useCallback((dateRange: IDateRange) => {
    setDateRange(dateRange);
  }, []);
  const [searchParams] = useSearchParams();
  const unit = searchParams.get('unit');
  const limit = searchParams.get('limit');
  const flags = useOxFlags();

  let developersCount = flags.developerCountByCommits
    ? organization?.developersCountCommits
    : organization?.developersCountAPI;

  // bc
  if (!developersCount) {
    developersCount = organization?.developersCount;
  }

  useMountEffect(() => {
    limit &&
      unit &&
      onDateRangeChange({
        unit: unit as DateUnit,
        limit: limit as unknown as number,
      });
  });

  const onIssueChipClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      const { name } = event.currentTarget.dataset;
      if (!name) {
        return;
      }
      navigateWithParameters(AppPages.ActiveIssues, {
        conditionalFilters: [
          {
            condition: ConditionalFiltersCondition.OR,
            fieldName: 'criticality',
            values: [CategoryRiskNames[name]],
          },
        ],
      });
    },
    [],
  );

  const isOrgAuthenticated = isAuthenticated && user?.org_id;
  if (currPage === AppPages.OrgLogin || currPage === AppPages.OrgSignup) {
    return null;
  }
  return (
    <AppBar
      position='sticky'
      elevation={0}
      sx={{
        overflow: 'auto',
        zIndex: theme.zIndex.drawer + 1,
        background: '#1D164D',
        color: theme.palette.getContrastText('#1D164D'),
      }}>
      <Toolbar
        sx={{
          px: important('8px'),
          gap: 2,
        }}>
        <AppMobileMenu />
        <Link
          style={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            gap: 4,
          }}
          to={AppPages.Dashboard}>
          <Logo style={{ height: 15 }} />
          <OxLogoTxt style={{ height: 20, maxWidth: 200 }} />
        </Link>

        {orgScanInfoBreakdownInfo?.violations && !isIssueCount && (
          <>
            <IssuesBadgeHeader
              top={3}
              handleClick={onIssueChipClick}
              severitiesAlerts={orgScanInfoBreakdownInfo?.violations}
              textColor={theme.palette.common.white}
            />
          </>
        )}
        {organization && !!developersCount && (
          <NoMaxWidthTooltip
            title={
              isOrgAuthenticated &&
              !isNull(orgScanInfo) &&
              !isNull(orgScanInfoBreakdownInfo) ? (
                <NonHistoricalDataTooltip
                  discoveredPolicies={{
                    enabled: enabledPolicies,
                    disabled: disabledPolicies,
                  }}
                  activePoliciesCount={enabledPolicies}
                  orgScanInfo={orgScanInfo}
                  numberOfDevs={developersCount}
                />
              ) : (
                ''
              )
            }>
            <div>
              {developersCount > 0 && (
                <DownloadsUsersDialog orgId={organization.id}>
                  <ProtectingDevelopersBadge numberOfDevs={developersCount} />
                </DownloadsUsersDialog>
              )}
            </div>
          </NoMaxWidthTooltip>
        )}
        <div className={classes.content}>
          <div className={classes.controls}>
            {isOrgAuthenticated && <DataViewSelectorContainer />}
            {isOrgAuthenticated && (
              <DateRangeSelector
                onChange={onDateRangeChange}
                filter={dateRange}
              />
            )}
            {isAuthenticated && (
              <>
                {isDevelopment() ? (
                  <OrganizationSelectorContainerV2 />
                ) : (
                  <OrganizationSelectorContainerV1 />
                )}
                <ExceedScanLimitContainer />
                {(realScanId || isDemoEnabled) && <DemoScanButton />}
                {isOrgAuthenticated && !realScanId && !isDemoEnabled && (
                  <DeltaScanButton />
                )}

                <FeedbackButton />
                <OpenDocsButton />
              </>
            )}
            <>
              <ToggleThemeButton />
              {isOrgAuthenticated && <UserMenuContainer />}
            </>
          </div>
          {!isAuthenticated && <LoginButtonContainer />}
        </div>
      </Toolbar>
    </AppBar>
  );
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    justifyContent: 'flex-end',
    '& .MuiSvgIcon-root': {
      width: '0.95rem',
      height: '0.95rem',
    },
  },
  scanningText: {
    color: `white !important`,
  },
  disabledScan: {
    color: important('rgba(255, 255, 255, 0.3)'),
    backgroundColor: important('rgba(255, 255, 255, 0.12)'),
    border: important('none'),
  },
}));

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export default AppHeader;
