import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { WhatsNewPoller } from 'whats-new-ui';
import CreateOrganizationContainer from '../../organizations/containers/CreateOrganizationContainer';
import PageNotFound from '../pages/page-not-found/PageNotFound';
import ChangeTitleOnRouteChange from './routing/ChangeTitleOnRouteChange';
import { PageLoader } from './routing/PageLoader';
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import { allRoutes } from './routing/all-routes';
import Auth0ErrorHandler from './user/Auth0ErrorHandler';
import PersistParamsOnPathNameChange from './routing/PersistParamsOnPathNameChange';
import { AppOrgRouteParamProvider } from './providers/app-organization-provider/AppOrgRouteParamProvider';
import { AppOrgInitializeProvider } from './providers/app-organization-provider/AppOrgInitializeProvider';
import { pathBuilder } from '../utils/route-checker-utils';

export const AppRoutesV2: FC = () => {
  return (
    <>
      <Auth0ErrorHandler>
        <Routes>
          <Route path='/' element={<AppOrgInitializeProvider />}>
            <Route element={<PublicRoute />}>
              {allRoutes.public.map(({ path, element: Page }) => (
                <Route
                  key={path}
                  path={pathBuilder([path])}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <Page />
                    </Suspense>
                  }
                />
              ))}
            </Route>
            <Route element={<PrivateRoute />}>
              {allRoutes.privateNoOrg.map(({ path, element: Page }) => (
                <Route
                  key={path}
                  path={pathBuilder([path])}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <Page />
                    </Suspense>
                  }
                />
              ))}
            </Route>
            <Route element={<AppOrgRouteParamProvider />}>
              <Route path='/:orgId' element={<PrivateRoute />}>
                {allRoutes.private.map(({ path, element: Page }) => (
                  <Route
                    key={path}
                    path={pathBuilder(['/:orgId', path])}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <Page />
                      </Suspense>
                    }
                  />
                ))}
              </Route>
            </Route>
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Auth0ErrorHandler>
      <ChangeTitleOnRouteChange />
      <CreateOrganizationContainer />
      <PersistParamsOnPathNameChange />
      <WhatsNewPoller />
    </>
  );
};
