import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  IconButton,
  Chip,
  Tooltip,
} from '@mui/material';
import {
  MdContentCopy,
  MdCheckCircle,
  MdCancel,
  MdSource,
  MdPerson,
  MdSettings,
  MdArchive,
} from 'react-icons/md';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../active-issues/stores/issues-store';
import { Location } from 'ox-react-components';
import { formatDistanceToNow } from 'date-fns';

interface HighlightedFieldProps {
  label: string;
  value: string | React.ReactNode;
}

export default function ServerlessDeploymentOperationView() {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const item = selectedIssue?.serverlessDeplyomentOperation;
  if (!item) return null;

  return (
    <Box p={2} borderRadius={2} boxShadow={2}>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card
            variant='outlined'
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
            }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <SectionTitle
                icon={<MdSettings size={20} />}
                title='Function Metadata'
              />
              <Divider sx={{ mb: 1 }} />
              <HighlightedField
                label='Function Name'
                value={item.functionName}
              />
              <HighlightedField label='Function ARN' value={item.functionArn} />
              <HighlightedField
                label='Internal Function Name'
                value={item.internalFunctionName}
              />
              <HighlightedField label='Cloud Region' value={item.cloudRegion} />
              <HighlightedField label='Version' value={item.version} />
              <HighlightedField label='Revision ID' value={item.revisionId} />
              <HighlightedField label='Code SHA256' value={item.codeSha256} />
              <HighlightedField label='Entry Point' value={item.entryPoint} />
              <HighlightedField
                label='Code Size'
                value={item.codeSize?.toString()}
              />
              <HighlightedField
                label='Memory Size'
                value={item.memorySize?.toString()}
              />
              <HighlightedField
                label='Timeout'
                value={item.timeout?.toString()}
              />
              <HighlightedField label='Runtime' value={item.runtime} />
              <HighlightedField
                label='Architectures'
                value={item.architectures?.join(', ')}
              />
              <HighlightedField label='Role' value={item.role} />
              <HighlightedField
                label='Recipient Account ID'
                value={item.recipientAccountId}
              />
              <HighlightedField label='Description' value={item.description} />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card
            variant='outlined'
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
            }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <SectionTitle
                icon={<MdSource size={20} />}
                title='Function Source Deployment'
              />
              <Divider sx={{ mb: 1 }} />
              {item.deploymentTime && (
                <HighlightedField
                  label='Deployment Time'
                  value={formatDistanceToNow(new Date(item.deploymentTime), {
                    addSuffix: true,
                  })}
                />
              )}
              <HighlightedField
                label='Source IP'
                value={item.sourceIPAddress}
              />
              {item.country && (
                <HighlightedField
                  label='Country'
                  value={<Location countryCode={item.country} />}
                />
              )}
              <HighlightedField label='City' value={item.city} />
              <HighlightedField label='Region' value={item.region} />
              <HighlightedField label='User Agent' value={item.userAgent} />
              <HighlightedField
                label='From Console'
                value={item.connectedFromConsole}
              />
            </CardContent>
          </Card>
        </Grid>

        {item.userIdentity && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card
              variant='outlined'
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
              }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <SectionTitle
                  icon={<MdPerson size={20} />}
                  title='Publisher Identity'
                />
                <Divider sx={{ mb: 1 }} />
                <HighlightedField
                  label='User Name'
                  value={item.userIdentity?.userName}
                />
                <HighlightedField
                  label='Principal ID'
                  value={item.userIdentity?.principalId}
                />
                <HighlightedField label='ARN' value={item.userIdentity?.arn} />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card
            variant='outlined'
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
            }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <SectionTitle
                icon={<MdArchive size={20} />}
                title='Function Storage'
              />
              <Divider sx={{ mb: 1 }} />
              <HighlightedField label='Location' value={item.location} />
              <HighlightedField label='S3 Key' value={item.s3Key} />
              <HighlightedField label='S3 Bucket' value={item.s3Bucket} />
              <HighlightedField label='Link to Code' value={item.linkToCode} />
              <HighlightedField label='Package Type' value={item.packageType} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

function SectionTitle({
  icon,
  title,
}: {
  icon: React.ReactNode;
  title: string;
}) {
  return (
    <Box display='flex' alignItems='center' mb={1}>
      <Box mr={1}>{icon}</Box>
      <Typography variant='subtitle1' color='primary' sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
    </Box>
  );
}

interface HighlightedFieldProps {
  label: string;
  value: string | React.ReactNode;
}

export function HighlightedField({ label, value }: HighlightedFieldProps) {
  if (value === null || value === undefined) return null;

  const isStringValue = typeof value === 'string';

  let strVal = '';
  if (isStringValue) {
    strVal = value as string;
  }

  const isBooleanString =
    isStringValue && (strVal === 'true' || strVal === 'false');

  if (isBooleanString) {
    const isTrue = strVal === 'true';
    return (
      <Box display='flex' alignItems='flex-start' mb={0.5}>
        <Box sx={{ minWidth: 130, flexShrink: 0 }}>
          <Typography variant='body2' fontWeight='bold' noWrap>
            {label}:
          </Typography>
        </Box>

        <Box flex={1} display='flex' alignItems='center'>
          {isTrue ? (
            <Chip
              label='TRUE'
              color='error'
              size='small'
              icon={<MdCheckCircle />}
              sx={{ mr: 1 }}
            />
          ) : (
            <Chip
              label='FALSE'
              color='success'
              size='small'
              icon={<MdCancel />}
              sx={{ mr: 1 }}
            />
          )}
        </Box>
      </Box>
    );
  }

  const isLongValue = isStringValue && strVal.length > 15;

  const handleCopy = () => {
    if (isStringValue && strVal) {
      navigator.clipboard.writeText(strVal);
    }
  };

  return (
    <Box display='flex' alignItems='center' mb={0.5}>
      <Box sx={{ minWidth: 130, flexShrink: 0 }}>
        <Typography variant='body2' fontWeight='bold' noWrap>
          {label}:
        </Typography>
      </Box>

      <Box flex={1} display='flex' alignItems='center'>
        {isStringValue ? (
          isLongValue ? (
            <>
              <Tooltip title={strVal}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    wordBreak: 'break-all',

                    borderRadius: 1,
                    px: 0.5,
                    mr: 1,
                  }}>
                  {strVal}
                </Typography>
              </Tooltip>
              <IconButton size='small' onClick={handleCopy}>
                <MdContentCopy />
              </IconButton>
            </>
          ) : (
            <Chip
              label={strVal}
              color='primary'
              size='small'
              variant='outlined'
              sx={{ mr: 1 }}
            />
          )
        ) : (
          <Box sx={{ mr: 1 }}>{value}</Box>
        )}
      </Box>
    </Box>
  );
}
