import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Divider, Theme, Typography } from '@mui/material';
import { CustomLink } from 'ox-react-components';
import ApiCallstack from 'ox-react-components/src/ApiCallstack/ApiCallstack';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { SeverityEvidenceItem } from './types';
import React from 'react';

const SeverityEvidence: FC<React.PropsWithChildren<SeverityEvidenceProps>> = ({
  evidence,
  withoutAutoNumbering,
  index,
}) => {
  const { theme, classes } = useStyles();

  if (evidence.key === 'Snippet' && evidence.snippet !== null) {
    return (
      <div className={classes.section} key={index}>
        <CustomLink
          fontWeight='bold'
          value={
            evidence.snippet.fileName !== null ? evidence.snippet.fileName : ''
          }
          key={index}
          href={evidence.link}
        />
        <SyntaxHighlighter
          language={
            evidence.snippet.language ? evidence.snippet.language : 'javascript'
          }
          style={theme.palette.mode === 'dark' ? dracula : docco}
          startingLineNumber={
            evidence.snippet.snippetLineNumber
              ? Number(evidence.snippet.snippetLineNumber)
              : 1
          }
          showLineNumbers>
          {evidence.snippet.text}
        </SyntaxHighlighter>
        <Divider />
      </div>
    );
  } else if (evidence.callBranch?.length) {
    return (
      <ApiCallstack
        codeLocations={[
          {
            link: evidence.link,
            callBranch: evidence.callBranch,
          },
        ]}
      />
    );
  } else {
    const keyIsEmpty = !evidence.key || evidence.key.trim() === '';
    const valueIsEmpty = !evidence.value || evidence.value.trim() === '';
    const bothEmpty = keyIsEmpty && valueIsEmpty;

    const baseNumbering =
      bothEmpty || withoutAutoNumbering ? '' : `${index + 1}.`;
    const keyDisplay = keyIsEmpty ? '' : evidence.key;
    const numberingWithSpace = baseNumbering ? baseNumbering + ' ' : '';

    let finalContent;

    if (bothEmpty) {
      // Both key and value are empty, so we just output an empty line
      finalContent = '\n';
    } else if (keyIsEmpty && !valueIsEmpty) {
      // Key is empty but value is present
      finalContent = (
        <>
          {numberingWithSpace}
          {evidence.value}
        </>
      );
    } else if (evidence.link !== null && valueIsEmpty) {
      // We have a key but no value (and a link)
      finalContent = (
        <>
          {numberingWithSpace}
          <Typography color='text.secondary' variant='caption' component='span'>
            {keyDisplay}
          </Typography>
        </>
      );
    } else if (!valueIsEmpty) {
      // We have both a key and a value
      finalContent = (
        <>
          {numberingWithSpace}
          <Typography color='text.secondary' variant='caption' component='span'>
            {keyDisplay}
          </Typography>
          {`: ${evidence.value}`}
        </>
      );
    } else {
      // We have a key but no link or value
      finalContent = (
        <>
          {numberingWithSpace}
          <Typography color='text.secondary' variant='caption' component='span'>
            {keyDisplay}
          </Typography>
        </>
      );
    }

    return (
      <CustomLink
        key={index}
        href={evidence.link}
        whiteSpace={
          bothEmpty || (evidence.link !== null && valueIsEmpty)
            ? 'pre-line'
            : undefined
        }>
        {finalContent}
      </CustomLink>
    );
  }
};

const useStyles = makeStyles()((theme: Theme) => ({
  section: {
    marginBlockStart: theme.spacing(0.8),
  },
}));

export interface SeverityEvidenceProps {
  index: number;
  withoutAutoNumbering?: boolean;
  evidence: SeverityEvidenceItem;
}

export default SeverityEvidence;
