import { _appPages as AppPages } from 'app-navigator';
import { lazy } from 'react';
import { RouteConfig } from './routing-types';

export const privateNoOrgRoutes: RouteConfig[] = [
  {
    path: AppPages.Onboarding,
    element: lazy(
      () => import('../../../onboarding/containers/OnboardingContainer'),
    ),
  },
];
