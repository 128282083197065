import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Client } from 'graphql-ws';
import { resolveServiceUrl } from './api-config';
import { createApiClient } from './create-api-client';
import { createWsClient } from './ws-client';

export const orgManagementClient = createApiClient({
  uri: resolveServiceUrl('ORG_MANAGEMENT_URL'),
});

export const connectorsClient = createApiClient({
  uri: resolveServiceUrl('CONNECTORS_URL'),
});

export const reportClient = createApiClient({
  uri: resolveServiceUrl('REPORT_URL'),
});

export const policiesClient = createApiClient({
  uri: resolveServiceUrl('POLICIES_URL'),
});

export const jiraClient = createApiClient({
  uri: resolveServiceUrl('JIRA_URL'),
});

export const slackClient = createApiClient({
  uri: resolveServiceUrl('SLACK_URL'),
});

export const exclusionClient = createApiClient({
  uri: resolveServiceUrl('EXCLUSION_URL'),
});

export const pdfClient = createApiClient({
  uri: resolveServiceUrl('PDF_URL'),
});

export const fileDownloadClient = createApiClient({
  uri: resolveServiceUrl('FILE_DOWNLOAD_URL'),
});

export const notificationClient = createApiClient({
  uri: resolveServiceUrl('NOTIFICATIONS_URL'),
});

export const pullRequestClient = createApiClient({
  uri: resolveServiceUrl('PULL_REQUEST_URL'),
});

export const apiKeyClient = createApiClient({
  uri: resolveServiceUrl('API_KEY_URL'),
});

export const whatsNewClient = createApiClient({
  uri: resolveServiceUrl('WHATS_NEW_URL'),
});

export const auditLogsClient = createApiClient({
  uri: resolveServiceUrl('AUDIT_LOGS_URL'),
});

export const chatGptClient = createApiClient({
  uri: resolveServiceUrl('GPT_URL'),
});

export const oscarClient = createApiClient({
  uri: resolveServiceUrl('OSCAR_URL'),
});

export const settingsClient = createApiClient({
  uri: resolveServiceUrl('SETTINGS_URL'),
});

export const tagsClient = createApiClient({
  uri: resolveServiceUrl('TAGS_URL'),
});

export const workflowClient = createApiClient({
  uri: resolveServiceUrl('WORKFLOW_URL'),
});

export const cicdInegrationClient = createApiClient({
  uri: resolveServiceUrl('CICD_INTEGRATION_URL'),
});

export const secretsManagementClient = createApiClient({
  uri: resolveServiceUrl('SECRETS_MANAGEMENT_URL'),
});

export const issueGraphClient = createApiClient({
  uri: resolveServiceUrl('ISSUE_GRAPH_URL'),
});

export const ticketingClient = createApiClient({
  uri: resolveServiceUrl('TICKETING_URL'),
});

export const messagingClient = createApiClient({
  uri: resolveServiceUrl('MESSAGING_URL'),
});

export const realtimeAlertsClients = ((): {
  apiClient: ApolloClient<NormalizedCacheObject>;
  wsClient: Client;
} => {
  const uri = resolveServiceUrl('REALTIME_ALERTS');
  const wsClient = createWsClient(uri);

  return {
    apiClient: createApiClient({
      uri,
      wsClient,
    }),
    wsClient,
  };
})();
